import useTranslation from "next-translate/useTranslation";
import Image from "next/image";
import React from "react";

interface ProductProps {
	imageSrc: string;
	name: string;
	slug: string;
	brand: string;
	currentPrice: number;
	originalPrice?: number;
	currency: string;
	discount?: string;
	isNew?: boolean;
}

const ProductGridItem: React.FC<ProductProps> = ({
	imageSrc,
	name,
	slug,
	brand,
	currentPrice,
	originalPrice,
	currency,
	discount,
	isNew,
}) => {
	const { t, lang } = useTranslation("common");

	const formatter = new Intl.NumberFormat(lang, {
		style: "currency",
		currency: currency,
	});

	return (
		<div>
			{/* https://www.notion.so/image/https%3A%2F%2Fs3.us-west-2.amazonaws.com%2Fsecure.notion-static.com%2Fe3cdaaff-124d-47ba-8431-010137084846%2FUntitled.png%3FX-Amz-Algorithm%3DAWS4-HMAC-SHA256%26X-Amz-Content-Sha256%3DUNSIGNED-PAYLOAD%26X-Amz-Credential%3DAKIAT73L2G45EIPT3X45%252F20230309%252Fus-west-2%252Fs3%252Faws4_request%26X-Amz-Date%3D20230309T032443Z%26X-Amz-Expires%3D86400%26X-Amz-Signature%3D7e9f377ba2ad55b80983bf27989144a60eab25675e966d54447142acaf30e212%26X-Amz-SignedHeaders%3Dhost%26x-id%3DGetObject?table=block&id=9f6a9a15-d55a-4b85-9490-64579c846e08&cache=v2 */}
			<a
				href={lang === "en" ? `/product/${slug}` : `/${lang}/product/${slug}`}
				className='relative block mb-2 overflow-hidden bg-gray-100 rounded-lg shadow-lg group h-96 lg:mb-3'>
				<Image
					width={500}
					height={500}
					key={imageSrc}
					src={imageSrc}
					loading='eager'
					// placeholder='blur'
					// blurDataURL={`${imageSrc}&width=16`}
					alt={`Photo of ${name} by ${brand}`}
					className='object-cover object-center w-full h-full transition duration-200 group-hover:scale-110'
				/>
				{/* <img
					src={imageSrc}
					loading='lazy'
				/> */}

				{discount && isNew && (
					<div className='absolute left-0 flex gap-2 bottom-2'>
						<span className='bg-red-500 text-white text-sm font-semibold tracking-wider uppercase rounded-r-lg px-3 py-1.5'>
							{discount}
						</span>
						<span className='bg-white text-gray-800 text-sm font-bold tracking-wider uppercase rounded-lg px-3 py-1.5'>
							New
						</span>
					</div>
				)}

				{discount && !isNew && (
					<div className='absolute left-0 flex gap-2 bottom-2'>
						<span className='bg-red-500 text-white text-sm font-semibold tracking-wider uppercase rounded-r-lg px-3 py-1.5'>
							{discount}
						</span>
					</div>
				)}

				{isNew && !discount && (
					<div className='absolute left-0 flex gap-2 bottom-2'>
						<span className='bg-white text-gray-800 text-sm font-bold tracking-wider uppercase rounded-lg px-3 py-1.5'>
							New
						</span>
					</div>
				)}
			</a>

			<div className='flex items-start justify-between gap-2 px-2'>
				<div className='flex flex-col'>
					<a
						href='#'
						className='text-lg font-bold text-gray-800 transition duration-100 hover:text-gray-500 lg:text-xl'>
						{name}
					</a>
					<span className='text-gray-500'>by {brand}</span>
				</div>

				<div className='flex flex-col items-end'>
					<span className='font-bold text-gray-600 lg:text-lg'>
						{formatter.format(currentPrice)}
					</span>
					{originalPrice && (
						<span className='text-sm text-red-500 line-through'>
							{formatter.format(originalPrice)}
						</span>
					)}
				</div>
			</div>
		</div>
	);
};

export default ProductGridItem;
