import { Box, Icon, Text } from "@chakra-ui/react";
import useTranslation from "next-translate/useTranslation";
import { Leaf, MapPinLine, Truck } from "phosphor-react";
import * as React from "react";
import MotionStack from "./MotionStack";

interface Props {}

const ProductBenefits: React.FC<Props> = (props) => {
	const { t } = useTranslation("common");

	return (
		<div className='flex flex-col items-start justify-center w-full px-4 py-6 space-y-4 lg:py-8 xl:py-8 lg:px-8 text-selfless-900 lg:flex-row lg:space-y-0 lg:space-x-8 xl:space-x-12'>
			<MotionStack
				direction={"row"}
				align={"center"}
				spacing={{ base: 4 }}
				initial={{ opacity: 0 }}
				whileInView={{ opacity: 1 }}
				transition={{ duration: 0.5 }}>
				<Icon as={Truck} minW='32px' width='32px' height='32px' weight='duotone' />

				<Box>
					<Text fontSize={{ base: "sm", md: "md", lg: "lg" }} fontWeight={"medium"}>
						{t("Free shipping")}
					</Text>
					<Text>{t("Fast and free shipping, worldwide")}</Text>
				</Box>
			</MotionStack>

			<MotionStack
				direction={"row"}
				align={"center"}
				spacing={{ base: 4 }}
				initial={{ opacity: 0 }}
				whileInView={{ opacity: 1 }}
				transition={{ duration: 0.5 }}>
				<Icon
					as={MapPinLine}
					minW='32px'
					width='32px'
					height='32px'
					weight='duotone'
				/>
				<Box>
					<Text fontSize={{ base: "sm", md: "md", lg: "lg" }} fontWeight={"medium"}>
						{t("Produced locally")}
					</Text>
					<Text>{t("Printed at the shop, closest to you")}</Text>
				</Box>
			</MotionStack>

			<MotionStack
				direction={"row"}
				align={"center"}
				spacing={{ base: 4 }}
				initial={{ opacity: 0 }}
				whileInView={{ opacity: 1 }}
				transition={{ duration: 0.5 }}>
				<Icon as={Leaf} minW='32px' width='32px' height='32px' weight='duotone' />
				<Box>
					<Text fontSize={{ base: "sm", md: "md", lg: "lg" }} fontWeight={"medium"}>
						{t("Greener Printing")}
					</Text>
					<Text>{t("Using sustainably sourced, FSC-certified paper and wood")}</Text>
				</Box>
			</MotionStack>
		</div>
	);
};

export default ProductBenefits;
