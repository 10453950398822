
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import BlogPostItem from "@/components/BlogPostItem";
import ExpandedFooter from "@/components/navigation/ExpandedFooter";
import NavigationMinimal from "@/components/navigation/NavigationMinimal";
import ProductBenefits from "@/components/ProductBenefits";
import ProductGridItem from "@/components/ProductGridItem";
import Testimonials from "@/components/Testimonials";
import type { ReviewsType } from "data/reviews";
import { getAllPages, PageData } from "lib/notion/getAllPages";
import { getAllProducts, ProductData } from "lib/notion/getAllProducts";
import { GetPageBySlugAndLocaleResult } from "lib/notion/getPage";
import type { GetStaticProps, NextPage } from "next";
import { NextSeo } from "next-seo";
import useTranslation from "next-translate/useTranslation";
import Image from "next/image";
import Link from "next/link";
const getStaticProps: GetStaticProps = async (context) => {
    const locale = context.locale as "en" | "th";
    try {
        const productsPromise = getAllProducts(locale);
        const blogPostsPromise = getAllPages(locale, "Blog Post");
        // Wait for all data to resolve
        const [reviewsData, products, blogPosts] = await Promise.all([
            // Import reviews data from prebuilt data/reviews.json
            import("../data/reviews.json"),
            productsPromise,
            blogPostsPromise,
        ]);
        const reviews = Object.values(reviewsData);
        return { props: { reviews, products, blogPosts } };
    }
    catch (err) {
        // we don't want to publish the error version of this page, so
        // let next.js know explicitly that incremental SSG failed
        throw err;
    }
};
interface Props {
    data: GetPageBySlugAndLocaleResult;
    reviews: ReviewsType;
    products: ProductData[];
    blogPosts: PageData[];
}
const PosterPage: NextPage<Props> = ({ reviews, products, blogPosts }) => {
    const { t } = useTranslation("common");
    return (<div className='relative'>
			<NextSeo title='Sustainable Products for your home' description='Sustainable products for your home. We are a small team of designers and makers who are passionate about creating sustainable products for your home.'/>

			<NavigationMinimal />

			<section className='overflow-hidden bg-caramel-50'>
				<div className='flex flex-col lg:flex-row lg:items-stretch lg:min-h-[800px]'>
					<div className='relative flex items-center justify-center w-full lg:order-2 lg:w-7/12'>
						<div className='absolute bottom-0 right-0 hidden lg:block'>
							<img loading='lazy' className='object-contain w-auto h-48' src='/images/curved-lines.png' alt=''/>
						</div>

						<div className='relative px-4 pt-24 pb-16 text-center sm:px-6 md:px-24 2xl:px-32 lg:py-24 lg:text-left'>
							<h1 className='text-4xl font-bold text-black'>{t("home_hero_title")}</h1>
							<p className='mt-8 text-xl text-black'>{t("home_hero_description")}</p>
						</div>
					</div>

					<div className='relative w-full overflow-hidden lg:order-1 h-96 lg:h-auto lg:w-5/12'>
						<div className='absolute inset-0'>
							<Image loading='eager' className='object-cover w-full h-full' src='/images/sustainable-01.jpg' width={1000} height={1000} alt='Sustainable Products for your home'/>
						</div>
					</div>
				</div>
			</section>

			<section className='py-6 bg-white sm:py-16 lg:py-24'>
				<div className='px-4 mx-auto max-w-screen-2xl md:px-8'>
					<div className='flex items-baseline justify-center gap-4 mb-10 sm:flex-row md:mb-16'>
						<h2 className='text-xl font-bold text-center text-gray-800 lg:text-3xl md:mb-6'>
							Featured Products
						</h2>

						<Link className='text-sm font-bold underline md:text-lg text-caramel-500' href={{
            pathname: "/product",
            href: "/product",
        }}>
							View all products
						</Link>
					</div>

					{/* Products */}
					<div className='grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-4 md:gap-x-6 gap-y-8'>
						{products?.map((product) => (<ProductGridItem slug={product.slug} key={product.id} brand={"Selfless Studio"} name={product.name} imageSrc={product.media[0].file.url} currentPrice={product.price} currency={product.currency}/>))}
					</div>
				</div>
			</section>

			<section className='container py-6 mx-auto bg-white sm:py-8 md:py-12 md:pt-32'>
				<div className='px-4 mx-auto max-w-screen-2xl md:px-8'>
					<div className='flex flex-col rounded-lg md:flex-row'>
						<div className='relative h-64 md:min-h-[30vh] bg-caramel-200 lg:w-1/2 sm:block rounded-md'>
							<Image loading='lazy' className='object-cover w-full h-full' src='/images/sustainable-01.jpg' width={800} height={800} alt='Sustainable Products for your home'/>
						</div>

						<div className='relative flex items-center w-full p-4 overflow-hidden rounded-md md:-top-24 bg-selfless-200 lg:w-1/2 sm:p-8 lg:pl-10'>
							<div className='flex flex-col items-center w-full sm:block'>
								<div className='mb-4 sm:mb-8'></div>
								<div className='mb-4 sm:mb-8'>
									<h2 className='mb-4 text-xl font-bold text-center text-caramel-600 sm:text-2xl lg:text-3xl sm:text-left'>
										{t("home_sustainable_title")}
									</h2>
									<p className='text-center text-gray-500 sm:text-left'>
										{t("home_sustainable_description")}
									</p>
								</div>
							</div>

							<div className='absolute right-0 hidden -bottom-8 lg:block'>
								<img loading='lazy' className='object-contain w-auto h-48' src='/images/curved-lines.png' alt=''/>
							</div>
						</div>
					</div>
				</div>
			</section>

			<section className='container px-4 py-6 mx-auto bg-white sm:py-8 lg:py-16'>
				<div className='max-w-2xl mx-auto mb-10 md:mb-16'>
					<h2 className='mb-4 text-xl font-bold text-center text-caramel-600 sm:text-2xl lg:text-3xl'>
						{t("home_sustainable_community_title")}
					</h2>

					<p className='text-center text-gray-500'>
						{t("home_sustainable_community_description")}
					</p>
				</div>

				<div className='grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-4 md:gap-x-6 gap-y-8'>
					{blogPosts?.map((blog) => (<BlogPostItem key={blog.slug} blog={blog}/>))}
				</div>

				<Link className='flex justify-center py-4 my-4 font-bold text-center underline text-caramel-500' href={{
            pathname: "/blog/",
            href: "/blog/",
        }}>
					Read all blog posts
				</Link>
			</section>

			<ProductBenefits />

			<Testimonials reviews={reviews}/>

			<ExpandedFooter />
		</div>);
};
export default PosterPage;

    async function __Next_Translate__getStaticProps__186e2ad1a67__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__186e2ad1a67__ as getStaticProps }
  