import { PageData } from "lib/notion/getAllPages";
import useTranslation from "next-translate/useTranslation";
import Image from "next/image";
import Link from "next/link";

const BlogPostItem = ({ blog }: { blog: PageData }) => {
	const { lang } = useTranslation("common");

	return (
		<Link
			key={blog.slug}
			href={{
				pathname: `/[slug]/`,
				href: `/${blog.slug}`,
				query: { slug: blog.slug },
			}}
			className='relative flex flex-col h-64 overflow-hidden bg-gray-100 rounded-lg shadow-lg group md:h-64 xl:h-96'>
			<Image
				src={blog.media[0]?.file.url}
				width={500}
				height={500}
				loading='lazy'
				alt={blog.title}
				className='absolute inset-0 object-cover object-center w-full h-full transition duration-200 group-hover:scale-110'
			/>

			<div className='absolute inset-0 pointer-events-none bg-gradient-to-t from-gray-800 to-transparent md:via-transparent'></div>

			<div className='relative p-4 mt-auto'>
				<span className='block text-sm font-bold text-white'>
					{/* Format as 22.12.2022 */}
					{new Date(blog.lastEditedTime?.last_edited_time || "").toLocaleDateString(
						lang,
						{
							day: "numeric",
							month: "long",
							year: "numeric",
						}
					)}
				</span>
				<h2 className='mb-2 text-xl font-semibold text-white transition duration-100'>
					{blog.title}
				</h2>

				<span className='font-semibold text-caramel-400'>Read more</span>
			</div>
		</Link>
	);
};

export default BlogPostItem;
