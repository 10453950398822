import useTranslation from "next-translate/useTranslation";
import { ReactNode } from "react";

import MotionBox from "@/components/MotionBox";
import MotionStack from "@/components/MotionStack";
import {
	Avatar,
	Box,
	BoxProps,
	Container,
	Flex,
	Heading,
	HStack,
	Stack,
	Text,
	useColorModeValue,
} from "@chakra-ui/react";

import type { ReviewsType } from "data/reviews";
import { Star } from "phosphor-react";

const Testimonial = ({ children }: { children: ReactNode }) => {
	return (
		<Box
			minW='xs'
			sx={{
				scrollSnapAlign: "center",
			}}>
			{children}
		</Box>
	);
};

const TestimonialContent = ({ children }: { children: ReactNode }) => {
	return (
		<Stack
			bg={useColorModeValue("white", "gray.800")}
			boxShadow={"xl"}
			p={8}
			rounded={"2xl"}
			align={"center"}
			pos={"relative"}
			_after={{
				content: `""`,
				w: 0,
				h: 0,
				borderLeft: "solid transparent",
				borderLeftWidth: 16,
				borderRight: "solid transparent",
				borderRightWidth: 16,
				borderTop: "solid",
				borderTopWidth: 16,
				borderTopColor: useColorModeValue("white", "gray.800"),
				pos: "absolute",
				bottom: "-16px",
				left: "50%",
				transform: "translateX(-50%)",
			}}>
			{children}
		</Stack>
	);
};

const TestimonialHeading = ({ children }: { children: ReactNode }) => {
	return (
		<Heading as={"h3"} fontSize={"xl"}>
			{children}
		</Heading>
	);
};

const TestimonialText = ({ children }: { children: ReactNode }) => {
	return (
		<Text
			textAlign={"center"}
			color={useColorModeValue("gray.600", "gray.400")}
			fontSize={"sm"}>
			{children}
		</Text>
	);
};

const TestimonialAvatar = ({
	src,
	name,
	title,
}: {
	src?: string;
	name: string;
	title: string;
}) => {
	return (
		<Flex align={"center"} mt={8} direction={"column"}>
			<Avatar src={src} mb={2} name={name} bg='caramel.500' color='caramel.50' />
			<Stack spacing={-1} align={"center"}>
				<Text fontWeight={600}>{name}</Text>
				<Text fontSize={"sm"} color={useColorModeValue("gray.600", "gray.400")}>
					{title}
				</Text>
			</Stack>
		</Flex>
	);
};

export default function Testimonials(
	props: BoxProps & { reviews: ReviewsType }
) {
	// @ts-expect-error
	const { lang }: { lang: "en" | "th" } = useTranslation("common");

	const reviews = props.reviews;

	const language = lang.toUpperCase() as "EN" | "TH";

	const reviewsFilteredByLanguage = reviews
		.filter((review) => {
			// @ts-expect-error
			return review?.[language]?.rich_text?.[0]?.text.content;
		})
		.sort((a, b) => {
			return (
				// @ts-expect-error
				b?.[language]?.rich_text?.[0]?.text.content.length -
				// @ts-expect-error
				a?.[language]?.rich_text?.[0]?.text.content.length
			);
		});

	return (
		<Box bg={useColorModeValue("selfless.100", "selfless.200")} {...props}>
			<Container
				maxW={"100%"}
				py={{ base: 8, lg: 16, xl: 24, "2xl": 32 }}
				px={0}
				as={Stack}
				spacing={[12, 16, 24]}>
				<MotionBox
					initial={{ opacity: 0 }}
					whileInView={{ opacity: 1 }}
					transition={{ duration: 0.5 }}>
					<Heading textAlign={"center"} as='h2' fontSize={"5xl"}>
						Real fans. <br /> Real reviews.
					</Heading>
				</MotionBox>

				<MotionStack
					initial={{ opacity: 0 }}
					whileInView={{ opacity: 1 }}
					transition={{ duration: 0.5 }}
					direction={{ base: "row" }}
					spacing={{ base: 8 }}
					px={[4, 8]}
					w='full'
					overflowX='scroll'
					sx={{
						msOverflowStyle: "none",
						scrollbarWidth: "none",
						"&::-webkit-scrollbar": {
							display: "none",
						},
						"&::-webkit-scrollbar-thumb": {
							display: "none",
						},
						scrollSnapType: "x proximity",
						scrollPadding: [4, 8],
					}}>
					{reviewsFilteredByLanguage.map((review, index) => {
						return (
							<Testimonial key={index}>
								<TestimonialContent>
									<HStack align='center' spacing={{ base: 1 }} color='goldenrod' mb='2'>
										<Star size={24} weight='fill' />
										<Star size={24} weight='fill' />
										<Star size={24} weight='fill' />
										<Star size={24} weight='fill' />
										<Star size={24} weight='fill' />
									</HStack>

									<TestimonialHeading>
										{
											// @ts-expect-error
											review?.[lang.toUpperCase()]?.rich_text?.[0]?.text.content
										}
									</TestimonialHeading>
									{/* <TestimonialText>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Auctor neque sed imperdiet nibh lectus feugiat nunc sem.
                  </TestimonialText> */}
								</TestimonialContent>
								<TestimonialAvatar
									// src={ 'https://images.unsplash.com/photo-1586297135537-94bc9ba060aa?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=100&q=80' }
									name={review?.Name?.title?.[0]?.text?.content || ""}
									title={review.Source.select.name}
								/>
							</Testimonial>
						);
					})}
				</MotionStack>
			</Container>
		</Box>
	);
}
